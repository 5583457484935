.dropdown_container {
  .country_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    .country {
      .country_flag {
        img {
          max-width: 24px;
          width: 100%;
          height: 18px;
        }
      }
    }
    span {
      border: none;
      background: none;
      color: #fff;
      &:focus {
        outline: none;
      }
    }
  }
  .dropdown_section {
    position: relative;
    .dropdown {
      position: absolute;
      right: 3px;
      top: 15px;
      background: #fff;
      color: #000;
      line-height: 40px;
      transition: all 0.3s;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      &.openModelAnimation {
        visibility: visible;
        opacity: 1;
        animation: zoomIn 300ms ease-out forwards;
      }
      &.closeModelAnimation {
        opacity: 0;
        animation: zoomOut 300ms ease-out forwards;
        pointer-events: none;
      }
      @keyframes zoomOut {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        100% {
          opacity: 0;
          transform: scale(0.9);
        }
      }
      @keyframes zoomIn {
        0% {
          opacity: 0;
          transform: scale(0.9);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
      ul {
        padding: 10px 13px;
        margin: 0;
        li {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .country_name {
            padding-left: 10px;
            padding-right: 25px;
            font-size: 14px;
            text-transform: uppercase;
          }
          img {
            max-width: 24px;
            width: 100%;
            height: 18px;
          }
        }
      }
    }
  }
  // .expanded {
  //     opacity: 1;
  //     visibility: visible;
  // }
}
