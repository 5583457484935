.loading{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    height: 100vh;
    .loading_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 84px 0;
        height: 100%;
        img {
          animation: rotate 1s infinite linear;
          height: 100px;
          width: 100px;
        }
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
}