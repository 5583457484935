@import "../../styles/var";

.header_container {
  background-color: #1067c6;
  padding: 16px 0;
  position: relative;
  .content_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .header_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // gap: 15px;
      .question {
        color: #fff;
        margin-right: 25px;
        margin-left: 15px;
      }
      .country {
        margin-right: 18px;
      }
      .property_detail {
        margin-right: 30px;
      }
      .profile {
        margin-left: 15px;
        position: relative;
        img {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          border: 1px solid #fff;
          cursor: pointer;
        }
        .toggle {
          position: absolute;
          background-color: #fff;
          box-shadow: 0px 4px 15px 0px rgba(136, 136, 136, 0.1);
          border-radius: 6px;
          right: 0;
          top: 50px;
          z-index: 1;
          text-align: left;
          padding: 8px;
          opacity: 0;
          visibility: hidden;
          &.openModelAnimation {
            visibility: visible;
            opacity: 1;
            animation: zoomIn 300ms ease-out forwards;
          }
          &.closeModelAnimation {
            opacity: 0;
            animation: zoomOut 300ms ease-out forwards;
            pointer-events: none;
          }
          @keyframes zoomOut {
            0% {
              opacity: 1;
              transform: scale(1);
            }
            100% {
              opacity: 0;
              transform: scale(0.9);
            }
          }
          @keyframes zoomIn {
            0% {
              opacity: 0;
              transform: scale(0.9);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }

          button {
            color: map-get($colors, "secondary");
            font-size: 14px;
            text-decoration: none;
            background: none;
            border: none;
            text-transform: capitalize;
            font-family: "Inter", sans-serif;
            width: 180px;
            //   padding: 10px 20px;
            height: 50px;
            text-align: left;
            display: flex;
            align-items: center;
            border-radius: 6px;
            &:hover {
              background-color: #eee;
            }
            img {
              border-radius: 0;
              width: 18px;
              height: 18px;
              border: none;
            }
            span {
              margin: 0;
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}
