@import "../../styles/var";

.menus {
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  .menu_items {
    &:hover {
      // cursor: pointer;
      border-radius: 6px;
      background: #4086d169;
      // border: 1px solid #4085d1;
    }
    .menu_wrapper {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 0 12px;
      .link {
        text-decoration: none;
      }
      .name {
        display: flex;
        justify-content: left;
        align-items: center;
        height: 40px;
        span {
          color: map-get($colors, "white");
          font-family: "Inter", sans-serif;
          font-size: 15px;
          font-weight: 400;
          padding-left: 10px;
          text-transform: capitalize;
        }
        img {
          width: 20px;
          color: map-get($colors, "white");
        }
      }
      span {
        cursor: pointer;
      }
      .arrow {
        padding-left: 8px;
        cursor: pointer;
        color: map-get($colors, "white");
      }
    }
    .activeMenu {
      border-radius: 6px;
      background: #4085d1;
      // border: 1px solid #4085d1;
    }
  }
  .dropdown_items {
    position: absolute;
    padding-top: 10px;
    z-index: 9;
    cursor: pointer;
    .dropdown {
      background: #fff;
      box-shadow: 0px 4px 15px 0px rgba(136, 136, 136, 0.1);
      // padding: 21px;
      padding: 10px;
      max-width: 300px;
      width: 100%;
      .dropdown_item {
        padding: 10px;
        border-radius: 6px;
        a {
          text-decoration: none;
          color: map-get($colors, "secondary");
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-weight: 400;
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}
// @include large-screen{
//     .menus{
//         max-width: 1100px;
//         width: 100%;
//     }
// }
// @include small-screen{
//     .menus{
//         display: grid;
//         grid-template-columns: repeat(4,1fr);
//         gap: 0;
//         max-width: 900px;
//         width: 100%;
//         padding: 0 30px;
//         .menu_items{
//             margin-bottom: 20px;
//         }
//     }
// }
