@font-face {
  font-family: "Arial";
  src: url("../../public/font/ARIAL.TTF");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arial-medium";
  src: url("../../public/font/ArialMdm.ttf");
  font-style: normal;
  font-display: swap;
}
body {
  background: #f4f9fc !important;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
:global {
  .dropzone {
    border-radius: 6px;
    border: 1px dashed #888;
    padding: 12px;
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .dropzone.active {
    border-color: red;
  }
  .rooms .STEP2 .css-ma3s50-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 44px !important;
  }
  .payment .STEP2 .special_input input {
    padding-left: 55px;
  }
}
:global {
  .basic_occupancy {
    input {
      padding-left: 130px;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

// .openModelAnimation {
//   visibility: visible;
//   opacity: 1;
//   animation: zoomIn 300ms ease-out forwards;
// }
// .closeModelAnimation {
//   opacity: 0;
//   animation: zoomOut 300ms ease-out forwards;
//   pointer-events: none;
// }
// @keyframes zoomOut {
//   0% {
//     opacity: 1;
//     transform: scale(1);
//   }
//   100% {
//     opacity: 0;
//     transform: scale(0.9);
//   }
// }
// @keyframes zoomIn {
//   0% {
//     opacity: 0;
//     transform: scale(0.9);
//   }
//   100% {
//     opacity: 1;
//     transform: scale(1);
//   }
// }
