@import "../../styles/var";

.property_detail_wrapper {
  border-radius: 6px;
  border: 1px solid #fff;
  padding: 6px 12px;
  color: map-get($colors, "white");
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.42px;
  text-transform: capitalize;
  .number {
    margin: 0 12px;
    border-radius: 4px;
    background: #4085d1;
    padding: 4px 8px;
  }
  button {
    background: none;
    border: none;
  }
  .property_dropdown {
    position: relative;
    .dropdown {
      position: absolute;
      top: 12px;
      background-color: #fff;
      box-shadow: 0px 4px 15px 0px rgba(136, 136, 136, 0.1);
      width: 292px;
      right: -12px;
      max-height: 688px;
      overflow-y: scroll;
      z-index: 15;
      border-radius: 4px;
      opacity: 0;
      visibility: hidden;
      &.openModelAnimation {
        visibility: visible;
        opacity: 1;
        animation: zoomIn 300ms ease-out forwards;
      }
      &.closeModelAnimation {
        opacity: 0;
        animation: zoomOut 300ms ease-out forwards;
        pointer-events: none;
      }
      @keyframes zoomOut {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        100% {
          opacity: 0;
          transform: scale(0.9);
        }
      }
      @keyframes zoomIn {
        0% {
          opacity: 0;
          transform: scale(0.9);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
      .property_data {
        font-family: "Arial", sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.42px;
        text-transform: capitalize;
        color: #4085d1;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        .name {
          text-align: left;
        }
        .number {
          color: #fff;
        }
      }
    }
  }
}
